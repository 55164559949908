import { CNCRecombee } from '@customTypes/recombee/CNCRecombee';

import { maintainTextLength, pad, monthsTranslates } from '@utils/text';

import { getTrackListItem } from '@utils/gtm';

import { getImageSources } from '@utils/image';
import * as Sentry from '@sentry/browser';
import { premiumSVGdefault, premiumSVGauto } from '@box/listArticle/Recombee/premiumSVG';

// Vytvořit HTML strukturu článku na základe DOMu z Twig
interface RenderListArticleFromTwigProps {
  hideAuthor?: boolean;
  hideDate?: boolean;
  hideMagazineName?: boolean;
  hideTime?: boolean;
  author: string;
  categoryNames: string[];
  position: number;
  publishedAt: Date;
  imageUrl: string;
  id: number;
  isPremium: boolean;
  listName: string;
  mainCategoryName: string;
  mainCategoryUrl: string;
  magazineName?: string;
  crossScenario: boolean;
  title: string;
  url: string;
  source?: string;
  systemId?: string;
  recombeeSrcSet?: CNCRecombee.SRCset;
}

export const renderListArticleFromTwig = ({
  author,
  hideAuthor = false,
  hideDate = false,
  hideMagazineName = false,
  hideTime = false,
  categoryNames,
  position,
  publishedAt,
  id,
  imageUrl,
  isPremium,
  listName,
  mainCategoryName,
  mainCategoryUrl,
  magazineName,
  crossScenario,
  title,
  url,
  source,
  systemId,
  recombeeSrcSet,
}: RenderListArticleFromTwigProps) => {
  // Pokud jde o články odjinud (z jiných titulů), tak se použije template [data-recombee-box-pattern-crossscenario] v HTML kodu, protože má rozdílné zobrazení článků na mobilním zařízení. Jinak se použivá pro doporučené články pattern data-recombee-box-pattern
  const recombeePattern: HTMLTemplateElement | null = !crossScenario
    ? document.querySelector<HTMLTemplateElement>('[data-recombee-box-pattern]')
    : document.querySelector<HTMLTemplateElement>('[data-recombee-box-pattern-crossscenario]');

  if (!recombeePattern) {
    Sentry.captureException('Recombee: Nenalezen pattern pro výpis článků.');
    throw new Error('Recombee: Nenalezen pattern pro výpis článků.');
  }
  const elementTemplate = recombeePattern.content.firstElementChild?.cloneNode(
    true
  ) as HTMLDivElement;

  // GTM
  const elementId = `${listName}-${id.toString()}`;
  const trackList = getTrackListItem({
    item: {
      elementId,
      category: categoryNames ? [...categoryNames] : null,
      author: author ? [author] : null,
      premium: isPremium,
      position,
      list: listName,
      title,
      url,
      id,
      systemId,
      type: 'article',
      source,
    },
  }) as string;

  // add GTM attributes
  elementTemplate.setAttribute('data-track-list', trackList);
  elementTemplate.setAttribute('data-track-element-id', elementId);
  elementTemplate.setAttribute('data-article-id', id.toString());

  // title
  const titleReplace = elementTemplate.querySelector('[data-ac-title]');
  if (titleReplace) titleReplace.innerHTML = maintainTextLength(title, 115);

  // link
  const linksReplace = elementTemplate.querySelectorAll('a');
  linksReplace.forEach((link) => {
    link.setAttribute('href', url);
    link.setAttribute('data-io-canonical-url', url);
    link.setAttribute('title', title);
    if (crossScenario) link.setAttribute('data-track-crosslink', '1');
  });

  // authors
  const authorReplace: HTMLElement | null = elementTemplate.querySelector('[data-ac-authors]');
  if (authorReplace) {
    if (!hideAuthor && author && author.length > 0) authorReplace.innerHTML = author;
    else {
      authorReplace.remove();
      elementTemplate.querySelector('.meta')?.remove();
    }
  }

  // time
  const timeReplace: HTMLElement | null = elementTemplate.querySelector('[data-ac-time]');
  if (timeReplace) {
    if (hideTime) timeReplace.remove();
    else timeReplace.innerHTML = `${pad(publishedAt.getHours())}:${pad(publishedAt.getMinutes())}`;
  }

  // date
  const dateReplace: HTMLElement | null = elementTemplate.querySelector('[data-ac-date]');
  if (dateReplace) {
    if (hideDate) dateReplace.remove();
    else
      dateReplace.innerHTML = `${publishedAt.getDate()}.
      ${monthsTranslates[publishedAt.getMonth()]} ${publishedAt.getFullYear()}`;
  }

  // category
  const categoryReplace = elementTemplate.querySelector('[data-ac-category]');

  if (categoryReplace) {
    const categoryLinkReplace: HTMLLinkElement | null =
      categoryReplace.querySelector('[data-ac-category-link]');

    if (crossScenario && magazineName) {
      if (hideMagazineName) categoryReplace.remove();
      else {
        categoryReplace.innerHTML = magazineName;
        categoryLinkReplace?.remove();
      }
    } else if (mainCategoryName && mainCategoryUrl && !crossScenario) {
      if (categoryLinkReplace) {
        categoryLinkReplace.setAttribute('href', mainCategoryUrl);
        categoryLinkReplace.innerHTML = mainCategoryName;
      } else categoryReplace.innerHTML = mainCategoryName;
    } else {
      categoryReplace.remove();
    }
  }

  // premium
  const premiumSticker: HTMLElement | null = elementTemplate.querySelector('.premium-brand');

  if (!isPremium) {
    premiumSticker?.remove();
  } else {
    // magazín auto.cz má jiné logo pro premium obsah (Auto plus svg, jiné magazíny naopka standardní SVG premium)
    if (magazineName == 'auto.cz' && premiumSticker) premiumSticker.innerHTML = premiumSVGauto;
    else if (premiumSticker) premiumSticker.innerHTML = premiumSVGdefault;
  }

  // image (lze rozšířit o large obrázek)
  const recombeeSources = getImageSources({
    isRecombee: true,
    recombeeSrcSet: {
      small: recombeeSrcSet?.small,
      medium: recombeeSrcSet?.medium,
      large: recombeeSrcSet?.large,
    },
  });
  const picture = `
    <picture style="aspect-ratio: 328 / 184">
      ${recombeeSources.join('')}
      <img src="${imageUrl}" alt="${title}" title="${title}" width="263" height="148" class="lazyload" loading="lazy">
    </picture>
  `;

  const imageReplace: HTMLElement | null = elementTemplate.querySelector('[data-ac-image]');
  if (imageReplace) imageReplace.innerHTML = picture;

  // rating
  const ratingReplace: HTMLElement | null = elementTemplate.querySelector('[data-ac-rating]');
  if (ratingReplace) {
    ratingReplace.remove();
  }

  return elementTemplate;
};

export default renderListArticleFromTwig;
